import React from 'react'
import { navigate } from 'gatsby'
import { getStoredCity } from '@ecommerce/shared'
import LocationSelector from '../components/LocationSelector'
import { LocationSelectorOption } from '../graphql/global'
import withPageTransition from '../components/withPageTransition'

const RedirectPage = () => {
  const params =
    typeof window !== 'undefined' && window.location.search ? new URLSearchParams(window.location.search) : null
  const to = params?.get('to')
  const paramsArray: string[] = []
  if (params) {
    params.forEach((param, key) => {
      if (key !== 'to') paramsArray.push(`${key}=${param}`)
    })
  }

  const query = paramsArray.join('&')

  try {
    const city = getStoredCity()
    if (city) {
      navigate(`/${city.slug}/${to}${query ? `?${query}` : ''}`, { replace: true })
      return null
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error(e)
  }

  const onSelect = (option: LocationSelectorOption) => {
    navigate(`/${option.slug}/${to}${query ? `?${query}` : ''}`, { replace: true })
  }

  return <LocationSelector onClose={() => null} hasCloseButton={false} excludeExternal onSelect={onSelect} />
}

export default withPageTransition(RedirectPage)
